<template>
    <v-card
    class="mx-auto"
    style="max-width: 500px;"
        >

        <v-toolbar
            color="green"
            cards
            dark
            flat
        >
            <v-btn 
                @click= "close"
                icon>
            <v-icon>mdi-close-thick</v-icon>
            </v-btn>
            <v-card-title class="text-h6 font-weight-regular">
            Login
            </v-card-title>
        </v-toolbar>
        <v-form
            ref="form"
            v-model="form"
            @submit.prevent="submit"
            class="pa-4 pt-6"
        >
            <v-alert type="error" v-if="message != ''">
            {{message}}
            </v-alert>
            <v-text-field
                v-model="user.username"
                :rules="[rules.required]"
                label="Username / E-Mail"
                style="min-height: 96px">
            </v-text-field>
            <v-text-field
                v-model="user.password"
                :rules="[rules.required]"
                label="Password"
                name="password" 
                autocomplete="off"
                style="min-height: 96px"
                type="password">
            </v-text-field>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                text
                @click="$refs.form.reset()"
                >
                Clear
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                :disabled="!form"
                :loading="isLoading"
                class="white--text"
                color="green"
                depressed
                type="submit"              
                >
                Submit
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>

</template>


<script>

export default {
    data () {
        return {
            name: "login",   

            form: false,
            isLoading: false,
            user: {
                password: undefined,
                username: undefined,
            },
            rules: {
                required: v => !!v || 'This field is required',
            }, 
            loading: false,
            message: "",
        }
    },

    computed: {

        loggedIn: function() {
            return this.$store.state.auth.status.loggedIn;
        },

    },
    methods:{
 
        submit() {
            
            this.loading = true;
            this.$store.dispatch("auth/login", this.user).then(
                () => {
                    this.$refs.form.reset();
                    // this.$router.push({name:"Company"});
                    
                    
                },
                (error) => {
                    this.loading = false;

                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.message =
                            (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                }
            );
        },

        close() {
            this.$emit('close');
        }
    },
}
</script>